$wds2-colour-nissanblack: rgba(0, 0, 0, 1);
$wds2-colour-nissangrey: rgba(239, 239, 239, 1);
$wds2-colour-nissanwhite: rgba(255, 255, 255, 1);
$wds2-colour-validation-success-onlight: rgba(10, 128, 53, 1);
$wds2-colour-validation-success-ondark: rgba(14, 190, 79, 1);
$wds2-colour-action-focus-ondark: rgba(84, 133, 240, 1);
$wds2-colour-action-hover-ondark: rgba(204, 204, 204, 1);

$accent-colour: $wds2-colour-nissanblack;
$text-colour: #343434;
$body-font-size: 15px;
$regular-font-family: 'Nissan Regular', 'Helvetica', 'Arial', sans-serif;
$light-font-family: 'Nissan Light', 'Helvetica', 'Arial', sans-serif;

@use '~@cognitranlimited/itis-web/dist/styles' with (
    $colour-accent: $accent-colour,
    $body-background: white,
    $body-font-family: $regular-font-family,
    $body-font-size: $body-font-size,
    $body-text-colour: $text-colour,
    $small-border-radius: 0px,

    $Button-border-radius: 40px,

    $heading-font-family: $light-font-family,
    $heading-font-weight: 400,
    $heading-text-transform: uppercase,
    $heading-text-colour: black,

    $heading1-font-size: $body-font-size + 14px,
    $heading2-font-size: $body-font-size + 10px,
    $heading3-font-size: $body-font-size + 8px,
    $heading4-font-size: $body-font-size + 4px,
    $heading5-font-size: $body-font-size + 3px,
    $heading6-font-size: $body-font-size,

    $Sidebar-background: $wds2-colour-nissanblack,
    $Sidebar-text-colour: $wds2-colour-nissanwhite,
    $Sidebar-light-text-colour: gray,

    $SidebarFooter-text-colour: $wds2-colour-nissanwhite,

    $SidebarSection-border: $accent-colour,
    $SidebarSection-background: $accent-colour,

    $SidebarSectionItem-border: (border-left: 3px solid transparent),
    $SidebarSectionItem-border-selected: (border-left-color: $wds2-colour-nissanwhite),
    $SidebarSectionItem-background-selected: rgba(255, 255, 255, 0.25),
    $SidebarSectionItem-background-hover: none,
    $SidebarSectionItem-text-colour-hover: $wds2-colour-nissanwhite,

    $Header-icon-font-family: $regular-font-family,
    $Header-icon-colour: black,

    $Button-font-size: 14px,
    $Button-font-weight: normal,
    $Button-padding: 8px 16px,

    $Panel-box-shadow: none,
    $Panel-border: 1px solid gainsboro,

    $NewLookPanel-background: $wds2-colour-nissangrey
);

@use '~@cognitranlimited/itis-web/dist/styles/check-sheets';
@use '~@cognitranlimited/itis-web/dist/styles/vehicle-park';
@use '~@cognitranlimited/itis-web/dist/styles/campaigns';
@use '~@cognitranlimited/itis-web/dist/styles/auxilio';

@use 'fonts';

.Button {
    letter-spacing: inherit;
}

.VerticalTabBar {
    &-selected {
        background: $accent-colour;

        a {
            color: white !important;
        }
    }
}

.Panel-bottomLink {
    a,
    button {
        font-family: $regular-font-family;
    }
}

.Link-title,
.LinkButton-title {
    font-family: $regular-font-family !important;
}

.Panel:not(.NewLookPanel):not(.Panel-info) {
    border-top: 2px solid $accent-colour;
}

.NewLookPanel {
    border-left: 2px solid $accent-colour;
}

.SubscriptionFilterPanel-filter .Select {
    width: 100%;
}

.SubscriptionsTableRow {
    .ButtonLink,
    .ButtonLink:hover {
        padding: 6px 14px;
    }
}

.ExpandedSidebar_Logo {
    width: 100%;
    display: flex;
    justify-content: center;
    background: $wds2-colour-nissanblack;

    img {
        padding: 6px;
    }
}

.SidebarLogo {
    filter: invert(1);
}

.SidebarSectionItem {
    &:hover {
        text-decoration: underline;
    }
}

.DocumentHeader-container button[aria-label="Feedback"] {
    display: none;
    }

div.MuiPopover-paper li:has(svg[data-icon="comments"])  {
    display: none;
}

form.PreferencesForm div > section:first-of-type {
    display: none;
}

div.AccountScreen-tabs > ul > li[role="tab"] {
     a[href="/account/password"],
     a[href="/account/email"],
     a[href="/account/delete"] {
        display: none;
    }
}
